import React, { Component } from "react";
import { API, graphqlOperation } from 'aws-amplify';
import Amplify, { Auth } from 'aws-amplify';
import { createVideotimestamp } from '../graphql/mutations'
import { withAuthenticator } from 'aws-amplify-react'; // or 'aws-amplify-react-native';
import CustomLogin from './CustomLogIn'
//import Amplify from '@aws-amplify/core'
//Amplify.Logger.LOG_LEVEL = 'DEBUG';

import awsconfig from '../config';

Amplify.configure(awsconfig);

class Add extends Component {

  constructor() {
    super();
    this.state = { url : '', title: '', description: '', tags: ''}
    this.handleChange = this.handleChange.bind(this)
    this.handlePaste = this.handlePaste.bind(this)
    this.createVideo = this.createVideo.bind(this)
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  async componentDidMount(){
	await Auth.currentUserPoolUser().then((userInfo) => {
		if(userInfo){
	      const { username } = userInfo;
	      this.setState({username: username})
  		}
    })
  }

  handlePaste(e) {
    this.setState({[e.target.name]: e.clipboardData.getData('Text')})
  }

  async createVideo(e){
  	e.preventDefault()
  	
  	let description = this.state.description.trim().replace(/\n/g, ';;').split(';;')
  		.filter(x => x.trim().replace(/\s/g,"") !== "")
  		.map((x, i) => {
  				//console.log(i + ':' + x)
	  			x = x.trim()
	  			x = x.replace(/(^[0-9]{1}:[0-9]{2})\s/g, '0$1 ') //0:00 cases
	  			x = x.replace(/(\s)([0-9]{1}:[0-9]{2}$)/g, ' 0$2')
	  			x = x.replace(/(^[0-9]{2}:[0-9]{2})\s/g, '00:$1 ') //00:00 cases
				  x = x.replace(/(\s)([0-9]{2}:[0-9]{2}$)/g, ' 00:$2')

  				let regEnd = /([0-9]{2}:[0-9]{2}:[0-9]{2}$)/g //00:00:00 at end
  				let res = regEnd.exec(x)
  				x = res ? res[0] + '::' + x.slice(0, res.index) : x

  				x = x.replace(/([0-9]{1,}:[0-9]{2}:[0-9]{2})\s/, '$1::') //00:00:00 at start


  				x = x.trim()
  				x = x.charAt(x.length - 1) === ':' ? x.slice(0, -1) : x
  				x = x.charAt(x.length - 1) === '-' ? x.slice(0, -1) : x
  	  		return x.trim()
  			
  			})
  		.join(';;')
  	//this.setState({description: description})

  	
  	let obj = {username: this.state.username, videourl: this.state.url.split('watch?v=')[1].split('&')[0], 
	      title: this.state.title, description: description, tags: this.state.tags, createdAt: new Date().toISOString()}
	obj = Object.entries(obj).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {})
	await API.graphql(graphqlOperation(createVideotimestamp,{input: obj}));
	
	this.setState({url: '', title: '', description: '', tags: ''})
  }

  render() {
  return (
    <>
  	{   
  		<>
  		<p className="control">
          <input className="input" type="text" name="url" placeholder="url" value={this.state.url || ''} onChange={ this.handleChange }/>
        </p>
        <p className="control">
          <input className="input" type="text" name="title" placeholder="title" value={this.state.title || ''} onChange={ this.handleChange }/>
        </p>
  		<p className="control">
          <textarea className="textarea" name="description" value={this.state.description || ''} 
          onChange={ this.handleChange }
          //onPaste={(e) => this.handlePaste(e)}
          />
        </p>
        <p className="control">
          <input className="input" type="text" name="tags" placeholder="delimit tags with ," value={this.state.tags || ''} onChange={ this.handleChange }/>
        </p>
        <p className="control">
          <input className="button is-link" type="submit" value="Add" onClick={this.createVideo}/>
        </p>
        <br />
        <p className="control">
          <textarea className="textarea" name="description" value={this.state.description || ''} readOnly
          //onChange={ this.handleChange }
          //onPaste={(e) => this.handlePaste(e)}
          />
        </p>
        </>
  	}
  	</>
  )}

}

export default withAuthenticator(Add, { 
  authenticatorComponents: [<CustomLogin/>
]});