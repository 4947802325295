/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVideotimestamp = /* GraphQL */ `
  mutation CreateVideotimestamp(
    $input: CreateVideotimestampInput!
    $condition: ModelVideotimestampConditionInput
  ) {
    createVideotimestamp(input: $input, condition: $condition) {
      id
      username
      videourl
      title
      description
      tags
      playlist
      createdAt
      owner
    }
  }
`;
export const updateVideotimestamp = /* GraphQL */ `
  mutation UpdateVideotimestamp(
    $input: UpdateVideotimestampInput!
    $condition: ModelVideotimestampConditionInput
  ) {
    updateVideotimestamp(input: $input, condition: $condition) {
      id
      username
      videourl
      title
      description
      tags
      playlist
      createdAt
      owner
    }
  }
`;
export const deleteVideotimestamp = /* GraphQL */ `
  mutation DeleteVideotimestamp(
    $input: DeleteVideotimestampInput!
    $condition: ModelVideotimestampConditionInput
  ) {
    deleteVideotimestamp(input: $input, condition: $condition) {
      id
      username
      videourl
      title
      description
      tags
      playlist
      createdAt
      owner
    }
  }
`;
